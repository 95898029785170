<template>
  <div class="main-content">
    <h3 class="marginBottom30">{{ form.id ? '编辑' : '新增' }}</h3>
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               @submit="submit">
      <template slot="h3One">
        <h3>楼盘信息</h3>
      </template>

      <template slot="businessId">
        <div class="flex align-items justify-content-space-between">
          <div class="width_30">
            <el-select v-model="form.cityId" clearable placeholder="请选择市" @change="handleCity">
              <el-option v-for="(item,index) in cityData"
                         :key="index"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="width_30">
            <el-select v-model="form.countyId" clearable placeholder="请选择区域" @change="handleCounty">
              <el-option v-for="(item,index) in countyData"
                         :key="index"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="width_30">
            <el-select v-model="form.businessId" clearable placeholder="请选择商圈">
              <el-option v-for="(item,index) in businessData"
                         :key="index"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </template>

      <template slot="metros">
        <div class="flex align-items justify-content-space-between"
             v-for="(item,index) in form.metros" :key="index"
             :class="form.metros.length === index + 1 ? '': 'marginBottom20'"
        >
          <div class="width_30">
            <el-select v-model="item.metroId" clearable placeholder="请选择线路" @change="handelMetro($event,index)"
                       @visible-change="visibleChange">
              <el-option v-for="(item,index) in metroData"
                         :key="index"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="width_30">
            <el-select v-model="item.stationId" clearable placeholder="请选择站名"
                       @visible-change="visibleChange">
              <el-option v-for="(item,index) in form.metros[index].tableData"
                         :key="index"
                         :label="item.name"
                         :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="width_30 flex align-items">
            <el-button v-if="index === 0" type="primary" icon="el-icon-plus" circle @click="handelMetroAdd"></el-button>
            <el-button v-if="form.metros.length > 1" type="danger" icon="el-icon-minus" circle @click="handelMetroReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="longitude">
        <div>
          <el-input type="text"
                    v-model="form.longitude"
                    clearable maxlength="30"
                    placeholder="请输入 经度"
                    @blur="changeBlur($event,'longitude')"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"/>
          <div style="font-size: 12px;color: #4fa6ff;cursor: pointer" @click="goLink">（坐标经纬度获取，参考网址链接：https://lbs.qq.com/getPoint）</div>
        </div>
      </template>
      <template slot="latitude">
        <el-input type="text"
                  v-model="form.latitude"
                  clearable maxlength="30"
                  placeholder="请输入 纬度"
                  @blur="changeBlur($event,'latitude')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,6}/)"/>
      </template>

      <template slot="agentId">
        <el-button type="text" @click="handleAgent">{{ form.agentUsername || '请选择' }}</el-button>
      </template>

      <template slot="h3Two">
        <h3>周边配套及通勤</h3>
      </template>

      <template slot="arderList">
        <div class="flex align-items"
             v-for="(item,index) in form.arderList"
             :key="index"
             :class="form.arderList.length === index + 1 ? '' : 'marginBottom20'"
        >
          <el-input type="text" clearable
                    style="width: 80%;"
                    maxlength="30"
                    :show-word-limit="true"
                    v-model="form.arderList[index]"/>
          <div class="width_20 paddingLeft20">
            <el-button v-if="form.arderList.length === index + 1" type="primary" icon="el-icon-plus" circle @click="handelArderAdd"></el-button>
            <el-button v-if="form.arderList.length !== 1" type="danger" icon="el-icon-minus" circle @click="handelArderReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="trafficList">
        <div class="flex align-items"
             v-for="(item,index) in form.trafficList" :key="index"
             :class="form.trafficList.length === index + 1 ? '' : 'marginBottom20'"
        >
          <el-input type="text" clearable
                    style="width: 80%;"
                    maxlength="30"
                    :show-word-limit="true"
                    v-model="form.trafficList[index]"/>
          <div class="width_20 paddingLeft20">
            <el-button v-if="form.trafficList.length === index + 1" type="primary" icon="el-icon-plus" circle @click="handelTrafficAdd"></el-button>
            <el-button v-if="form.trafficList.length !== 1" type="danger" icon="el-icon-minus" circle @click="handelTrafficReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="educationList">
        <div class="flex align-items"
             v-for="(item,index) in form.educationList" :key="index"
             :class="form.educationList.length === index + 1 ? '' : 'marginBottom20'"
        >
          <el-input type="text" clearable
                    style="width: 80%;"
                    maxlength="30"
                    :show-word-limit="true"
                    v-model="form.educationList[index]"/>
          <div class="width_20 paddingLeft20">
            <el-button v-if="form.educationList.length === index + 1" type="primary" icon="el-icon-plus" circle @click="handelEducationAdd"></el-button>
            <el-button v-if="form.educationList.length !== 1" type="danger" icon="el-icon-minus" circle @click="handelEducationReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="medicalList">
        <div class="flex align-items"
             v-for="(item,index) in form.medicalList" :key="index"
             :class="form.medicalList.length === index + 1 ? '' : 'marginBottom20'"
        >
          <el-input type="text" clearable
                    style="width: 80%;"
                    maxlength="30"
                    :show-word-limit="true"
                    v-model="form.medicalList[index]"/>
          <div class="width_20 paddingLeft20">
            <el-button v-if="form.medicalList.length === index + 1" type="primary" icon="el-icon-plus" circle @click="handelMedicalAdd"></el-button>
            <el-button v-if="form.medicalList.length !== 1" type="danger" icon="el-icon-minus" circle @click="handelMedicalReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="lifeList">
        <div class="flex align-items"
             v-for="(item,index) in form.lifeList" :key="index"
             :class="form.lifeList.length === index + 1 ? '' : 'marginBottom20'"
        >
          <el-input type="text" clearable
                    style="width: 80%;"
                    maxlength="30"
                    :show-word-limit="true"
                    v-model="form.lifeList[index]"/>
          <div class="width_20 paddingLeft20">
            <el-button v-if="form.lifeList.length === index + 1" type="primary" icon="el-icon-plus" circle @click="handelLifeAdd"></el-button>
            <el-button v-if="form.lifeList.length !== 1" type="danger" icon="el-icon-minus" circle @click="handelLifeReduce(index)"></el-button>
          </div>
        </div>
      </template>

      <template slot="h3Three">
        <h3>房源介绍</h3>
      </template>


      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleBack">取消</el-button>
      </template>
    </avue-form>

    <el-dialog
        title="选择经纪人"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <avue-crud
          :data="tableData"
          :option="option"
          :table-loading="showLoading"
          :page.sync="page"
          @on-load="onLoad">
        <template #header>
          <div class="flex align-items marginBottom20">
            <el-button size="medium" type="primary" @click="handleClose">返回</el-button>
            <el-input size="medium" v-model="searchData.name" placeholder="输入姓名/手机号" class="marginLeft10 width200"/>
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
          </div>
        </template>
        <template slot="menu" slot-scope="{ row }">
          <el-button type="text" v-if="form.agentId === row.id" size="mini" icon="el-icon-delete" @click="handleDelete()">取消选择</el-button>
          <el-button type="text" v-else size="mini" icon="el-icon-plus" @click="handleChoice(row)">选择</el-button>
        </template>
      </avue-crud>
    </el-dialog>
  </div>
</template>

<script>
import {
  cityList,
  countyList,
  countyBusinessList,
  metroList,
  metroStationList,
  add,
  agentList,
  view,
  update
} from '@/api/build/manage'

export default {
  name: "info",
  data() {
    return {
      cityData: [],
      countyData: [],
      businessData: [],
      metroData: [],
      form: {
        id: '',
        name: '',
        cityId: '',
        countyId: '',
        businessId: '',
        metros: [{
          metroId: '',
          stationId: '',
          tableData: []
        }],
        detailAddress: '',
        longitude: '',
        latitude: '',
        agentId: '',
        agentUsername: '',
        sort: '',
        arderList: [''],
        trafficList: [''],
        educationList: [''],
        medicalList: [''],
        lifeList: [''],
        coreSell: '',
        houseType: '',
        transport: '',
        ambitus: '',
        village: '',
        mortgage: '',
        other: '',
        state: 2
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '',
            span: 24,
            row: true,
            prop: 'h3One',
            labelWidth: 0
          },
          {
            label: '楼盘名称',
            prop: 'name',
            maxlength: 15,
            showWordLimit: true,
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入楼盘名称');
                  callback(new Error('请输入楼盘名称'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '所属地区',
            prop: 'businessId',
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请选择所属地区');
                  callback(new Error('请选择所属地区'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '关联地铁',
            prop: 'metros',
            type: 'array',
            span: 12,
            row: true
          },
          {
            label: '详细地址',
            prop: 'detailAddress',
            maxlength: 30,
            showWordLimit: true,
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入详细地址');
                  callback(new Error('请输入详细地址'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '经度',
            prop: 'longitude',
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入经度');
                  callback(new Error('请输入经度'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '纬度',
            prop: 'latitude',
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入纬度');
                  callback(new Error('请输入纬度'));
                }
              },
              trigger: 'blur'
            }]
          },
          // {
          //   label: '选择新房经纪人',
          //   prop: 'agentId',
          //   span: 12,
          //   row: true,
          //   rules: [{
          //     required: true,
          //     validator: (rule, value, callback) => {
          //       if(value){
          //         callback();
          //       }else{
          //         this.$message.warning('请选择新房经纪人');
          //         callback(new Error('请选择新房经纪人'));
          //       }
          //     },
          //     trigger: 'blur'
          //   }]
          // },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            minRows: 1,
            maxRows: 999999,
            span: 12,
            row: true,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入排序');
                  callback(new Error('请输入排序'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '',
            prop: 'h3Two',
            labelWidth: 0,
            span: 24,
            row: true
          },
          {
            label: '楼盘详情',
            prop: 'arderList',
            type: 'array',
            span: 12,
            rules: [{
              required: true,
              message: "请输入楼盘详情",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (value.some(item => item === '')) {
                  callback(new Error('请输入楼盘详情'))
                } else {
                  callback()
                }
              }
            }]
          },
          {
            label: '交通',
            prop: 'trafficList',
            type: 'array',
            span: 12,
            rules: [{
              required: true,
              message: "请输入交通",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (value.some(item => item === '')) {
                  callback(new Error('请输入交通'))
                } else {
                  callback()
                }
              }
            }]
          },
          {
            label: '教育',
            prop: 'educationList',
            type: 'array',
            span: 12,
            rules: [{
              required: true,
              message: "请输入教育",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (value.some(item => item === '')) {
                  callback(new Error('请输入教育'))
                } else {
                  callback()
                }
              }
            }]
          },
          {
            label: '医疗',
            prop: 'medicalList',
            type: 'array',
            span: 12,
            rules: [{
              required: true,
              message: "请输入医疗",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (value.some(item => item === '')) {
                  callback(new Error('请输入医疗'))
                } else {
                  callback()
                }
              }
            }]
          },
          {
            label: '生活',
            prop: 'lifeList',
            type: 'array',
            span: 12,
            rules: [{
              required: true,
              message: "请输入生活",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (value.some(item => item === '')) {
                  callback(new Error('请输入生活'))
                } else {
                  callback()
                }
              }
            }]
          },
          {
            label: '',
            prop: 'h3Three',
            span: 24,
            row: true,
            labelWidth: 0
          },
          {
            label: '核心卖点',
            prop: 'coreSell',
            type: 'textarea',
            placeholder: ' ',
            maxlength: 500,
            showWordLimit: true,
            span: 12,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入核心卖点');
                  callback(new Error('请输入核心卖点'));
                }
              },
              trigger: 'blur'
            }]
          },
          // {
          //   label: '户型介绍',
          //   prop: 'houseType',
          //   type: 'textarea',
          //   placeholder: ' ',
          //   maxlength: 500,
          //   showWordLimit: true,
          //   span: 12,
          //   rules: [{
          //     required: true,
          //     validator: (rule, value, callback) => {
          //       if(value){
          //         callback();
          //       }else{
          //         // this.$message.warning('请输入户型介绍');
          //         callback(new Error('请输入户型介绍'));
          //       }
          //     },
          //     trigger: 'blur'
          //   }]
          // },
          {
            label: '交通出行',
            prop: 'transport',
            type: 'textarea',
            placeholder: ' ',
            maxlength: 500,
            showWordLimit: true,
            span: 12,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入交通出行');
                  callback(new Error('请输入交通出行'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '周边配套',
            prop: 'ambitus',
            type: 'textarea',
            placeholder: ' ',
            maxlength: 500,
            showWordLimit: true,
            span: 12,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入周边配套');
                  callback(new Error('请输入周边配套'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '小区信息',
            prop: 'village',
            type: 'textarea',
            placeholder: ' ',
            maxlength: 500,
            showWordLimit: true,
            span: 12,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入小区信息');
                  callback(new Error('请输入小区信息'));
                }
              },
              trigger: 'blur'
            }]
          },
          // {
          //   label: '权属抵押',
          //   prop: 'mortgage',
          //   type: 'textarea',
          //   placeholder: ' ',
          //   maxlength: 500,
          //   showWordLimit: true,
          //   span: 12,
          //   rules: [{
          //     required: true,
          //     validator: (rule, value, callback) => {
          //       if(value){
          //         callback();
          //       }else{
          //         // this.$message.warning('请输入权属抵押');
          //         callback(new Error('请输入权属抵押'));
          //       }
          //     },
          //     trigger: 'blur'
          //   }]
          // },
          {
            label: '其他',
            prop: 'other',
            type: 'textarea',
            placeholder: ' ',
            maxlength: 500,
            showWordLimit: true,
            span: 12,
            rules: [{
              required: true,
              validator: (rule, value, callback) => {
                if(value){
                  callback();
                }else{
                  // this.$message.warning('请输入其他');
                  callback(new Error('请输入其他'));
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '是否上架',
            prop: 'state',
            type: 'radio',
            span: 24,
            row: true,
            dicData: [
              {
                label: '上架',
                value: 1
              },
              {
                label: '下架',
                value: 2
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否上架",
              trigger: "blur"
            }]
          }
        ]
      },

      dialogVisible: false,
      searchData: {
        name: ''
      },
      page:{
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户id',
            prop: 'code'
          },
          {
            label: '用户姓名',
            prop: 'username'
          }
        ]
      },

      metrosDefault:[{
        metroId: '',
        stationId: '',
        tableData: []
      }]
    }
  },
  async mounted() {
    await this.cityList()
    this.$route.query.id && await this.view()
  },
  methods: {
    async view(){
      const { data } = await view({ id: this.$route.query.id })
      // console.log(data)
      const { metros, ...rest } = data;
      // console.log(metros)
      await this.handleCity(data.cityId)
      await this.handleCounty(data.countyId)
      for (const item of metros) {
        if(item.metroId){
          item.tableData = await this.choiceMetro(item.metroId)
        }
      }
      this.form = {
        ...rest,
        metros: metros.length === 0 ? this.metrosDefault : metros
      };
    },
    // 提交楼盘信息
    submit(form, done) {
      console.log(form)
      const { metros, ...rest } = form;
      console.log(metros)
      // 判断数组metros中字段是否存在空值
      const isMetro = metros.some(item => {
        return item.metroId === '' || item.stationId === ''
      })
      // if(isMetro) {
      //   this.$message.warning('请选择地铁线路和地铁站点')
      //   done()
      //   return
      // }
      const array = metros.map(item=>{
        if(item.metroId === '' || item.stationId === ''){
          return null
        }else{
          const { metroId, stationId } = item
          return {
            metroId,
            stationId
          }
        }
      }).filter(item => item)
      if(form.id){
        update({
          ...rest,
          metros: array
        }).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功')
            this.$router.go(-1)
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }else{
        add({
          ...rest,
          metros: array
        }).then(res => {
          if(res.code === 200){
            this.$message.success('添加成功')
            this.$router.go(-1)
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }
    },
    // 返回
    handleBack() {
      this.$router.go(-1)
    },
    // 获取城市列表
    async cityList() {
      const { data } = await cityList()
      // console.log(data)
      this.cityData = data || [];
      this.infoClearStr('cityId');
      this.infoClearStr('countyId');
      this.infoClearStr('businessId');

      this.infoClearArr('countyData');
      this.infoClearArr('businessData');
      this.infoClearArr('metroData');
      this.form.metros.map(item => {
        this.infoClearStr(`${item.metroId}`);
        this.infoClearStr(`${item.stationId}`);
        this.infoClearArr(`${item.tableData}`);
      })
    },
    // 选择城市获取区县列表
    async handleCity(e){
      if(e){
        const { data } = await countyList({ id: e })
        // console.log( data )
        this.countyData = data || [];
      }
      this.infoClearStr('countyId');
      this.infoClearStr('businessId');
      this.infoClearArr('businessData');

      this.form.metros.map(item => {
        item.metroId = ''
        item.stationId = ''
      })

      // 选择城市获取地铁列表
      await this.metroList(e);
    },
    // 选择区县获取商圈列表
    async handleCounty(e){
      if(e){
        const { data } = await countyBusinessList({ id: e })
        // console.log( data )
        this.businessData = data || [];
      }
      this.infoClearStr('businessId');
    },
    /* 获取地铁线路 */
    async metroList(e){
      if(e){
        const { data } = await metroList({ id: e })
        // console.log( '地铁', data )
        this.metroData = data || []
      }else{
        this.metroData = []
      }
    },
    /* 选择地铁线路 */
    async handelMetro(e,index){
      this.form.metros[index].metroId = e;
      this.form.metros[index].stationId = '';
      if(e){
        this.form.metros[index].tableData = await this.choiceMetro(e)
      }else{
        this.form.metros[index].tableData = []
      }
    },
    choiceMetro(e){
      return new Promise((resolve, reject) => {
        try {
          metroStationList({id: e}).then(res=>{
            resolve(res.data)
          })
        } catch (error) {
          reject([]);
        }
      });
    },
    /* 清空form中字符串 */
    infoClearStr(e){
      this.form[e] = '';
    },
    /* 清空data中数组 */
    infoClearArr(e){
      this[e] = [];
    },
    /* 添加地铁 */
    handelMetroAdd(){
      this.form.metros.push({
        metroId: '',
        stationId: '',
        tableData: []
      })
    },
    /* 删除地铁 */
    handelMetroReduce(i){
      this.form.metros.splice(i,1)
    },
    /* 输入框校验 */
    changeBlur(e,label){
      const value = e.target.value;
      if(value){
        if(isNaN(value)){
          e.target.value = ''
          this.form[label] = ''
        }else{
          this.form[label] = value
        }
      }else{
        this.form[label] = '';
        e.target.value = ''
      }
    },
    async onLoad(){
      this.showLoading = true;
      const { data } = await agentList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      })
      this.tableData = data.records || [];
      this.page.total = data.total;
      this.showLoading = false;
    },
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset(){
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.onLoad();
    },
    /* 选择 */
    handleChoice(row){
      // console.log(row)
      this.form.agentId = row.id;
      this.form.agentUsername = row.username;
    },
    handleDelete(){
      this.form.agentId = '';
      this.form.agentUsername = '';
    },
    /* 请选择经纪人 */
    async handleAgent(){
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.dialogVisible = true;
    },
    /* 添加楼盘信息 */
    handelArderAdd(){
      this.form.arderList.push('')
    },
    /* 删除楼盘信息 */
    handelArderReduce(i){
      this.form.arderList.splice(i,1)
    },
    /* 添加交通信息 */
    handelTrafficAdd(){
      this.form.trafficList.push('')
    },
    /* 删除交通信息 */
    handelTrafficReduce(i){
      this.form.trafficList.splice(i,1)
    },
    /* 添加教育信息 */
    handelEducationAdd(){
      this.form.educationList.push('')
    },
    /* 删除教育信息 */
    handelEducationReduce(i){
      this.form.educationList.splice(i,1)
    },
    /* 添加医疗信息 */
    handelMedicalAdd(){
      this.form.medicalList.push('')
    },
    /* 删除医疗信息 */
    handelMedicalReduce(i){
      this.form.medicalList.splice(i,1)
    },
    /* 添加医疗信息 */
    handelLifeAdd(){
      this.form.lifeList.push('')
    },
    /* 删除医疗信息 */
    handelLifeReduce(i){
      this.form.lifeList.splice(i,1)
    },
    /* 关闭弹框 */
    handleClose(){
      this.dialogVisible = false;
      this.searchData = {
        name: ''
      }
    },
    goLink(){
      window.open('https://lbs.qq.com/getPoint', '_blank');
    },

    visibleChange(e){
      if(e && !this.form.cityId){
        this.$message.warning('请先选择所属地区')
      }
    }
  }
}
</script>

<style scoped>
/deep/.avue-form .el-input .el-input__inner {
  padding-right: 75px;
}
</style>